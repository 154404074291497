export default {
  config: {
    "cancel": "cancel",
    "confirm": "confirm",
    "Today": "Today",
    "Yesterday": "Yesterday",
    "sixday": "7-days",
    "month":"30 days",
    "SMS": "Please enter the SMS verification code",
    "mobile":"Please enter mobile phone number",
    "loading":"loading",
    "gameerror":"Game error",
    "取消收藏":"Cancel favorites",
    "收藏成功":"Collection successful",
    "uploadImages":"Please upload images in correct format",
    "充值":"top up",
    "全屏":"full screen",
    "success":'Success',
    "身份信息修改成功":"Identity information modified successfully",
    "添加成功":"Added successfully",
    "全部":"All",
    "热门":"Hot",
    "最近":"Recently",
    "收藏":"Collect",
    "请输入搜索关键词":"Please enter search keywords",
    "logout":"Are you sure you want to logout?",
    "下属名称":"Subordinate name",
  },
  login: {
    "title": "Login",
    "Remember": "Remember",
    "password": "Forgot password",
    "Register": "Register",
    "account": "Don’t have an account",
    "userPlaceholder": "Username",
    "passwordPlaceholder": "Password",
    "tips": "Please enter 6 - 13 alphanumeric without special character",
    "SMS": "Please enter the SMS verification code",
    "mobile":"Please enter mobile phone number",
    "code":"Please enter the verification code",
    "Kind":"Kind tips",
    "first":"Please complete verification first",
    "graphic":"Please enter the graphic verification code",
    "Send":"Send code",
  },
  register: {
    "title": "Register",
    "Reset": "Reset",
    "userPlaceholder": "Please enter Username",
    "passwordPlaceholder": "Please enter Password",
    "confirmPlaceholder": "Confirm password",
    "phonePlaceholder": "Mobile number",
    "empty":"Content cannot be empty",
    "tips": "Please enter 6 - 13 alphanumeric without special character",
    "tipsTow": "Please enter username and password",
    "tipsTows": "Two passwords do not match",
    "tipsThree": "Please enter 10 - 11 alphanumeric without special character",
    "invitation":"Please enter the invitation code",
  },
  forget:{
    "Forgot":"Forgot password",
    "following":"Please retrieve your password in the following ways.",
    "newpassword":"Please enter new password",
    "passwordagain":"Please enter new password again",
    "wait":"Resetting password, please wait",
  },
  member: {
    "head": "My Account",
    "center": "Member Center",
    "Reward": "Reward Center",
    "Betting": "Betting Record",
    "Profit": "Profit And Loss",
    "Deposit": "Deposit Record",
    "Withdrawal": "Withdrawal Record",
    "Account": "Account Record",
    "MyAccount": "My Account",
    "Security": "Security Center",
    "Invite": "Invite friends",
    "Manual": "Manual rebate",
    "Rebate": "My Rebate",
    "Internal": "Internal Message",
    "Suggestion": "Suggestion",
    "Service": "Customer Service",
    "Signin": "Sign in",
    "Nickname": "Nickname",
    "DepositH": "Deposit",
    "Withdrawals": "Withdrawal",
    "Cards": "My Cards",
    "Benefits": "Benefits",
    "Overview":"Overview",
    "Registration":"Registration",
    "TeamManagement":"Team Management",
    "TeamBetting":"Team Betting",
    "TeamPL":"Team PL",
    "Announcement":"Announcement",
    "anage":"Agent center"
  },
  feedback: {
    "title": "Complaint / suggestion",
    "type": "Please select the question type",
    "contentTite": "Is there anything that could be improved?",
    "content": "Please enter content",
    "upload": "Upload",
    "code": "* Verification Code",
    "submit": "Submit",
    "select": "Please select the type of issue"
  },
  addBankCard: {
    "title": "Bank Account Number",
    "card": "Please fill in bank account number",
    "name": "Please fill in the name of the payee",
    "phone":"Please fill in your mobile phone number",
    "code":"Please fill in the verification code",
    "tips": "Please make sure the name matches the bank card to avoid withdrawal failure.",
    "submit": "Submit",
    "select": "Choose a bank",
    "address":"Please fill in branch information",
    "password": "Please fill in the fund password",
  },
  addBankCardPix:{
    "title":"Bind E-wallet",
    "group":"Select E-wallet group",
    "type":"E-wallet type",
    "agreement":"protocol",
    "submit":"Submit",
    "account":"Please fill in the account number",
    "address":"Please fill in branch information",
    "virtual":"virtual currency",
    "E-wallet":"E-wallet",
  },
  webEmail: {
    "title": "Internal Message",
    "Inbox": "Inbox",
    "selectAll": "Select All",
    "Sender": "Sender",
    "tit": "title",
    "Confirmation": "Confirmation",
    "delDes": "Are you sure you want to delete it？",
    "message":"No messages",
    "Sender":"Sender",
    "titls":"Title",
    "Time":"Time",
  },
  notice: {
    "title": "Announcement",
  },
  voucherCenter: {
    "title": "Deposit",
    "deposit": "Deposit method",
    "channels": "Payment channels",
    "amount": "Deposit Amount",
    "virtual": "Virtual currency protocol",
    "Next": "Next",
    "Minimum": "Minimum amount",
    "Maximum": "Maximum amount",
    "DepositInfo":"Deposit information",
    "order":"Order placed",
    "account":"deposit account",
    "money":"Deposit amount",
    "copy":"Copy",
    "tips":"Tips",
    "connection":"If you have any questions, please feel free to contact us.",
    "TransactionRecord":"Transaction Record",
    "CompleteDeposit":"Complete Deposit",
    "Another":"Another",
    "service":"service",
    "noActivity":"Not participating in the event",
    "Activity":"Activity",
    "depositorname":"Please enter the depositor's name",
    "successPay":"success! Please go to the deposit page to pay",
    "protocol":"wallet protocol",
    "cardName":"Bank card name",
    "address":"wallet address",
    "cardAddress":"Bank card account number",
    "virtual":"Amount of virtual coins",
    "Ordernumber":"Order number",
    "rate":"exchange rate",
    "period":"Validity period",
    "tips1":"1. Please confirm the charges before making payment, amount deposit will be based on the amount received.",
    "tips2":"2. Please complete the payment within 30min, estimate deposit time will be around 3min - 20min. If you didn't receive it within the duration, please provide your transaction ID, wallet address and transaction hash for faster processing, thanks.",
  },
  activity: {
    "title": "Promotion",
  },
  myWithdrawCards: {
    "title": "My Cards",
    "wallet": "E-wallet added",
    "emptyWallet": "empty wallet",
    "bank": "Bank account added",
    "emptyBank": "empty bank",
    "addnew": "added",
    "walletName": "E-wallet",
  },
  benefits: {
    "title": "Rank Benefits",
    "Rules": "Rules",
    "Next": "Next Rank",
    "up":"Previous level",
    "Levels": "Level Up Requirements",
    "Upgrade": "Upgrade",
    "Fulfil": "Fulfil all to level up",
    "TotalBet": "Total Bet",
    "TotalDeposit":"Total Deposit",
    "go": "GO",
    "withdrawals": "withdrawals per day",
    "quota": "Daily withdrawal limit",
    "UpgradesQuota": "Upgrade gift",
    "birthday": "Birthday gift",
    "week": "Weekly gift",
    "Completed":"Completed"
  },
  nickName: {
    "title": "Nickname",
    "Submit": "Submit",
    "Please": "Please enter your nickname",
    "complete": "Modifying nickname succeeded"
  },
  avatar: {
    "uploadImage": "Click the image to upload your own avatar",
    "Classic": "Classic avatar",
    "ok": "Ok",
  },
  withdraw: {
    "title": "Withdrawal",
    "mybank": "My bank accounts",
    "bank": "bank Accounts",
    "banks": "Empty Bank",
    "walletsss": "Empty E-wallets",
    "walletss": "E-wallets",
    "wallets": "Bound E-wallets",
    "Account": "Account number",
    "BindTiem": "Bind date",
    "withdrawTiem": "Withdrawal time",
    "Tips": "Tips",
    "other": "Note: Please make sure that your GCASH has been verified for withdrawal application. If the verification is not completed, it will not be credited to the account",
    "Daily": "Daily withdrawal",
    "Remaining": "Remaining withdrawal",
    "Times": "Times",
    "MainWallet": "Main wallet",
    "type": "Withdrawal type",
    "AvailableAmount": "Available amount",
    "balance": "Recall balance",
    "Ok":"Ok",
    "turnover":"Withdrawal turnover requirements",
    "completeTurnover":"Please complete the required turnover for withdrawal.",
    "GameType":"Game type",
    "RemainingRurnover":"Remaining rurnover",
    "General":"General",
    "amount":"amount",
    "mobile":"mobile",
    "verify_code":"Verification code",
    "trade_password":"Transaction password",
    "trade_password_p":"Please enter transaction password",
    "submit":"Submit",
    "BalanceSuccessful":"Balance recall successful",
    "Theinputamountcannotbelessthan":"The input amount cannot be less than",
    "greater":"greater than",
    "mobiles":"Please enter a 10 to 11 digit mobile phone number",
    "card":"Please add bank card",
    "money":"Please enter the withdrawal amount",
    "mobileE":"Mobile phone number cannot be empty",
    "verify_codeE":"Verification code cannot be empty",
    "order":"Withdrawal order has been submitted",
  },
  myAccount: {
    "title": "My Account",
    "username": "Username",
    "privacy": "We care about your privacy",
    "protected": "All the user data are encrypted to ensure your personal privacy is protected.",
    "nickname": "Please enter your nickname",
    "email": "Please enter your email",
    "real_name": "Please enter your name",
    "mobile": "Please enter your mobile",
    "real_name_tips": "Please make sure your name matches your withdrawal information",
    "email_tips": "Please enter 1 - 255 characters in mailbox format",
    "mobile_tips": "Please enter 10 - 11 characters",
    "Submit": "Submit",
  },
  vouReport: {
    "title": "Deposit Record",
    "Deposit": "Deposit ref",
    "Postscript": "Postscript",
    "ReceivedTime": "Received time",
    "fee": "Handling fee",
    "Activity": "Activity",
    "Request": "Request",
    "Received": "Received amount",
    "Status": "Status",
    "type": "Types",
    "discount":"Discount amount",
    "待付款":"Pending payment",
    "付款待审核":"Pending review",
    "已完成":"Completed",
    "已取消":"Canceled",
    "挂单":"pending order",
    "拒绝":"reject",
    "待出款":"Not paid",
  },
  withdrawReport: {
    "title": "Withdrawal Record",
    "Request": "Withdrawal amount",
  },
  gameRecord: {
    "title": "Betting Record",
    "all": "All",
    "order": "Order no.",
    "BetAmount": "Bet amount",
    "ValidBet": "Valid bet",
    "ProfitAndLoss": "Profit and loss",
    "Winnings": "Winnings",
  },
  loginChange: {
    "title": "Change login password",
    "tipContent": "Please enter 6 - 12 alphanumeric without special character",
    "Submit": "Submit",
    "oldNewPassowrd": "The two passwords are inconsistent",
    "kong": "Please enter the content",
    "oldPassword": "Enter your current password.",
    "newPassword": "Please enter a new password",
    "ConfirmPassword": "Confirm new password",
    "Confirm":"Login password changed successfully",
  },
  FundsPasswordBox: {
    "title": "Transaction Password",
    "exit":"Transaction password changed successfully",
    "add":"Add transaction password successfully",
  },
  securityCenter: {
    "title": "Security Center",
    "tips-up": "Your account security level is",
    "tips-nx": "Please improve your safety information",
    "information": "Personal information",
    "informationTips": "Complete personal information.",
    "loginPassword": "Change login password",
    "loginPasswordTips": "Recommended letter and number combination",
    "password": "Transaction Password",
    "passwordTips": "Set a fund password to improve the security of fund operations",
    "card": "Bank Account Number",
    "cardTips": "Please use a real bank account number",
    "wallet": "Bind E-wallet",
    "walletTips": "Bind E-wallet for withdrawal.",
    "login": "Logout",
    "loginTips": "Logout safely",
    "Safety": "Safety percentage",
    "LastIp": "Last login ip",
    "lastTiem": "Last login time",
    "high": "High",
    "In": "In",
    "low": "Low",
    "birthday":"Bind birthday",
    "birthdayTops":"Bind your birthday date",
    "birthdayTitle":"birthday"
  },
  myBankCards: {
    "title": "Bank Account Number",
    "addBank": "Bank account added",
    "Maximum": "Maximum",
    "allowed": "allowed",
    "Empty": "Empty bank"
  },
  myEWallet: {
    "title": "Bind E-wallet",
    "addBank": "E-wallet added",
    "Empty": "Empty E-wallet",
  },
  profitandloss: {
    "title": "Personal report",
    "Total": "Total P&L",
    "Deposit": "Deposit",
    "Activity": "Activity",
    "Income": "Income",
    "Withdrawal": "Withdrawal",
    "Expenses": "Expenses",
    "Betting": "Betting",
    "ValidBet": "Valid bet",
    "winAmount": "Win amount",
    "Rebate": "Rebate",
    "Bonus": "Bonus",
  },
  inviteFriends:{
    "title":"Invite friends",
    "Overview":"Overview",
    "Incomes":"Incomes",
    "Records":"Records",
    "Invitee":"Invitee",
    "TodayIncome":"Today's Income",
    "YesterdayIncome":"Yesterday's income",
    "Registers":"Registers",
    "ValidInvitees":"Valid Invitees",
    "URL":"Invitation URL",
    "share":"Share to your friends",
    "copy":"Copy link",
    "Rewardss":"Rewards",
    "Rewards":"Rewards released so far",
    "RevenueGoals":"Revenue goals",
    "Invite":"Invite",
    "target":" user to meet the target",
    "InvitationRewards":"Invitation Rewards",
    "DepositRebate":"Deposit Rebate",
    "BettingRebate":"Betting Rebate",
    "achievement":"Achievement rewards",
    "claimed":"claimed",
    "Leaderboard":"Leaderboard",
    "whoRewards":"Who received the rewards",
    "Incomecalculator":"Income calculator",
    "activeUser":" active users, expected revenue",
    "Depositors":"Depositors",
    "TotalIncome":"Total Income",
    "tips":"Note : The system updates the data every 15 minutes.",
    "RegistrationDate":"Registration date",
    "InviteDate":"Invite Date",
    "Username":"Username",
    "Amount":"Amount",
    "Total":"Total",
  },
  manualRebate:{
    "title":"Manual rebate",
    "date":"Date",
    "Claim":"Claim",
    "lotter":"Lotter"
  },
  overview:{
    "title":"Overview",
    "Logins":"Logins",
    "TeamPL":"Team P&L",
    "Customize":"Customize",
    "Teammember":"Team member",
    "Registers":"Registers",
    "Deposits":"Deposits",
    "BetCount":"Bet count",
    "FirstDeposits":"First deposits",
    "Withdrawals":"Withdrawals",
    "Deposit":"Deposit",
    "WithdrawalAmount":"Withdrawal Amount",
    "Bonus":"Bonus",
    "ValidBet":"Valid bet",
    "WinLoss":"Win/Loss",
  },
  agentRegister:{
    "title":"Account",
    "Downline":"Downline Registration",
    "Registration":"Link Registration",
    "Management":"Link Management",
    "AccountType":"Account type",
    "Agent":"Agent",
    "Player":"Player",
    "username":"Username",
    "password":"Login password (default is 123456)",
    "Remarks":"Remarks",
    "submit":"Submit",
    "Generate":"Generate link",
    "dec":"Details",
    "Time":"Time",
    "Username":"Username",
    "pleaseUsername":"Please enter username",
    "successfully":"User registration successful",
    "exitpassword":"Change login password",
    "url":"Platform URL",
    "copy":"Copy and close",
    "permanent":"permanent",
    "day":"one day",
    "sexDay":"7 days",
    "month":"30 days",
    "year":"one year",
    "remarksp":"Please enter remarks",
    "link":"Link registration successful",
    "Creation":"Creation date",
    "expiry":"Date of expiry",
    "RegisterTypes":"RegisterTypes",
    "Registers":"Registers",
    "Status":"Status",
    "Normal":"Normal",
    "Expired":"Expired",
    "Confirmation":"Confirmation",
    "deletion":"Confirm deletion：",
    "selectInviteCode":"Please select link to register",
    "linkList":"Linked list",
    "copyUrl":"Copy URL"
  },
  teamBetting:{
    "title":"Team Betting",
    "Subordinate":"Subordinate",
    "betRecord":'Bet record',
    "rounds":'rounds',
    "validBet":'Valid bet',
    "WinLoss":'Win/Loss',
    "Records":'Records',
    "totalRounds":'Total rounds',
    "date":"Date",
    "Vendors":"Vendors",
    "Default":"Default",
    "ValidBetAscending":"Valid bet-Ascending",
    "ValidBetDescending":"Valid bet-Descending",
    "WinLossAscending":"Win/Loss-Ascending",
    "WinLossDescending":"Win/Loss-Descending",
    "betNumsAscending":"Total number of bets-Ascending",
    "betNnumsAscending":"Total number of bets-Descending",
    "sort":"Sorting"
  },
  team:{
    "title":"Team Management",
    "Allsubordinates":"All subordinates",
    "Reset":"Reset",
    "Ok":"Ok",
    "Self":"self",
    "Balance":"Balance",
    "LastRTime":"Last login time",
    "RegistrationDate":"Registration date",
    "type":"Registration date",
    "all":"All",
    "Agent":"Agent",
    "Player":"Player",
    "login":"Login",
    "have":"Have login record",
    "noHave":"No login record",
    "Deposit":"Deposited",
    "noDeposit":"No deposit",
    "LastDepositTime":"Last deposit time",
    "firstDepositTime":"First deposit time",
    "sort":"Sorting",
    "Registrationdate-Descending":"Registration date-Descending",
    "Registrationdate-Ascending":"Registration date-Ascending",
    "time-Descending":"Last login time-Descending",
    "time-Ascending":"Last login time-Ascending",
    
  },
  home: {
    "hotGame": "Hot Games",
    "service": "Service",
    "cashBack": "Cash Back",
    "Withdraw": "Withdraw",
    "Bonus": "Bonus",
    "home": "Home",
    "agent": "Agent",
    "activity": "Promotion",
    "member": "Member",
    "Confirmation":"Confirmation",
    "logout":"Are you sure you want to logout?",
    "updates":"We would like to add it to your home screen for the latest free bets and bonus updates.",
    "Continue":"Continue",
    "benefits":"Enjoy more benefits!",
    "Category":"Category",
    "Providers":"Providers",
    "OtherGames":"Other Games",
    "inviteFriends":"invite"
  },
  record: {
    "title": "Account Record",
    "order": "Order no.",
    "Balance": "Balance",
    "Transaction": "Transaction amount",
  },
  pnl:{
    "title":"Team P&L",
    "DepositAscending":"Deposit-Ascending",
    "DepositDescending":"Deposit-Descending",
    "WithdrawalAscending":"Withdrawal-Ascending",
    "WithdrawalDescending":"Withdrawal-Descending",
    "BonusAscending":"Bonus-Ascending",
    "BonusDescending":"Bonus-Descending",
    "Subordinate":"Subordinate",
    "Deposit":"Deposit",
    "Withdrawal":"Withdrawal",
    "Bonus":"Bonus",
    "Validbet":"Valid bet",
    "WinLoss":"Win/Loss",
    "Profit":"Profit and loss",
    "Total":"Total"
  },
  signIn:{
    "title":"Sign in",
    "Last":"Last sign in",
    "totalBonus":"Sign in total bonus",
    "dayBouns":"",
    "dayBounss":"-DAY TOURNAMENT WITH PRIZES",
    "inToday":"Sign in today",
    "SignedIn":"Signed in",
    "deposit":"Minimum deposit amount",
    "bet":"Betting conditions",
    "bonus":"bonus",
    "rules":"Sign-in rules",
    "one":"您每天只需存入",
    "ones":"PHP，并完成",
    "oness":"所需的营业额，即可获得当天的登录奖励。",
    "tow":"连续登录次数越多，奖励越高，若跳过一天，奖励将重置为第一天的奖励。",
    "three":"完成连续7天登录后，签到奖励将会重置，并从第1天重新开始。",
    "recharge":"Please complete the deposit required for your sign-up bonus today",
    "bets":"Please complete the betting required for your sign-up bonus today",
    "当前存款金额":"Current deposit amount",
    "所需存款金额":"Required deposit amount",
    "当前投注金额":"Current betting amount",
    "所需投注金额":"Required betting amount",
    "第":"",
    "day":"Day",
  },
  receivingCenter:{
    'title':"Welfare Center",
    "promotion":"No promotion available yet",
    "Claim":"Claim",
    "DueDate":"Due Date",
    "Reward":"Reward",
    "Bonus":"Welfare",
    "领取成功":"Received successfully",
    "申请活动":"Apply for an event",
  },
  helpMe:{
    'title':"Rescue fund",
    "gifted":"Relief fund gifted to you",
    "fund":"Fund",
    "promotion":"No promotions yet",
    "Claim":"Claim",
    "DueDate":"Due Date",
    "Reward":"Reward",
    "Bonus":"Bonus",
    "领取成功":"Received successfully",
    "申请活动":"Apply for an event",
    "Product":"Product",
    "RescueFund":"Rescue fund",
    "AmountAvailable":"Amount available",
    "Slot":"Slot",
    "NetLoss":"Net loss"
  },
  bonus:{
    'title':"bonus",
    "REWARD":"REWARD TICKET",
    "ticket":"This ticket can be claimed only using the APP.",
    "Download":"Download",
    "恭喜您获得":"Congratulations on getting",
    "很遗憾":"It's a pity",
    "彩金":"bonus",
    "积分":"integral",
    "您未抽中任何奖品":"You didn't win any prizes",
    "再抽一次":"Smoke it again",
    "未中奖":"no win",
    "奖励":"award",
    "商品":"commodity",
    "票务记录":"Ticket records",
    "Remaining":"Remaining time",
    "Day":"Day",
    "min":"min",
    "hr":"hr",
    "hr":"hr",
    "sec":"sec",
    "ticketTitle":"Complete the task to claim your ticket",
    "completed":"Completed"
  }
};
