import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-175ef78a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "scrollList"
};
import { ref, onBeforeUnmount, onUnmounted, nextTick, onBeforeMount } from 'vue';
//定时器初始化

export default {
  __name: 'seamless',
  setup(__props) {
    let timer = ref(null);
    //ref绑定初始化
    let roll = ref(null);

    //组件销毁前需清除定时器
    onBeforeUnmount(() => {
      //清除定时器
      clearTimeout(timer.value);
    });
    //组件销毁后需清除定时器
    onUnmounted(() => {
      //清除定时器
      clearTimeout(timer.value);
    });

    //鼠标移入事件   
    // function testMove(){
    //     clearTimeout(timer.value)
    // }
    // //鼠标移出事件  
    // function testMend(){
    //     start()
    // }
    //开启定时器方法
    function start() {
      //清除定时器
      clearTimeout(timer.value);
      //定时器触发周期,移动速度
      let speed = ref(50);
      timer.value = setInterval(MarqueeTest, speed.value);
    }
    function MarqueeTest() {
      let test1 = roll.value;

      //判断组件是否渲染完成
      if (test1.offsetHeight == 0) {
        test1 = roll.value;
      } else {
        //如果列表数量过少不进行滚动
        if (test1.childNodes.length < 5) {
          clearTimeout(timer.value);
          return;
        }
        //组件进行滚动
        test1.scrollTop += 1;

        //判断滚动条是否滚动到底部
        if (test1.scrollTop == test1.scrollHeight - test1.clientHeight) {
          //获取组件第一个节点
          let a = test1.childNodes[0];
          //删除节点
          test1.removeChild(a);
          //将该节点拼接到组件最后
          test1.append(a);
        }
      }
    }
    //启动定时器
    //用nextTick 的原因是 需要等dom元素加载完毕后 再执行方法
    onBeforeMount(() => {
      setInterval(() => {
        start();
      }, 2000);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        onMousemove: _cache[0] || (_cache[0] = (...args) => _ctx.testMove && _ctx.testMove(...args)),
        onMouseleave: _cache[1] || (_cache[1] = (...args) => _ctx.testMend && _ctx.testMend(...args))
      }, [_createElementVNode("div", {
        ref_key: "roll",
        ref: roll,
        style: {
          "height": "3rem",
          "overflow": "hidden"
        }
      }, [_renderSlot(_ctx.$slots, "default")], 512)], 32)]);
    };
  }
};