import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "height": "100%"
  }
};
import dowApp from "./components/dowload/home.vue";
import prize from "./components/agent/prize.vue";
import TabBar from "./components/TabBar.vue";
import entry from "./components/home/entry.vue";
import { useRouter } from "vue-router";
import { showConfirmDialog } from "vant";
import { useStore } from "vuex"; 
import { ref, onBeforeMount, onUnmounted } from 'vue';
// 引入useStore 方法

export default {
  __name: 'App',
  setup(__props) {
    let pagePath = ref(["home", "activity"]);
    let pagePaths = ref(["home", "activity", "member"]);
    const bg = ref(baseConfigs.config_basic.sys_h5_img);
    const https = ref(baseConfigs.config_basic.image_cloud_url);
    const appLogo = ref(baseConfigs.config_basic.sys_app_logo);
    const sys_name = ref(baseConfigs.config_basic.sys_name);
    const show = ref(false);
    const handleClearNum = () => {
      let num = baseConfigs.config_basic.clear_num;
      if (localStorage.getItem("clear")) {
        if (localStorage.getItem("clear") != num) {
          showConfirmDialog({
            title: "confirm",
            message: "System update, please refresh the page"
          }).then(() => {
            sessionStorage.clear();
            localStorage.removeItem('tag_list');
            localStorage.setItem('clear', baseConfigs.config_basic.clear_num);
            location.reload();
          }).catch(() => {
            // on cancel
          });
        }
      }
    };
    // 引入vuex
    const store = useStore(); // 该方法用于返回store 实例
    const {
      dispatch
    } = useStore();
    // if (!localStorage.getItem("Device")) {
    //   show.value = true;
    //   setTimeout(() => {
    //     show.value = false;
    //   }, 2000);
    // }
    onBeforeMount(() => {
      setInterval(() => {
        handleClearNum();
      }, 20000);
      document.title = sys_name.value;
      var head = document.getElementsByTagName("head")[0];
      // 获取当前 favicon 元素
      var favicon = document.querySelector("link[rel*='icon']") || document.createElement("link");
      favicon.type = "image/x-icon";
      favicon.rel = "shortcut icon";
      // 设置新的 favicon 地址
      favicon.href = https.value + appLogo.value;

      // 如果当前 head 标签中不存在 favicon 元素，则将新的 favicon 添加到 head 标签中
      if (!document.querySelector("link[rel*='icon']")) {
        head.appendChild(favicon);
      }
      if (localStorage.getItem("Authorization")) {
        dispatch('login');
        dispatch("getDeviceUser");
        dispatch("getDeviceBounsNum");
        if (!localStorage.getItem("bank")) {
          dispatch("getDeviceBank");
        }
        dispatch("getDeviceWithdraw");
      }
    });
    onUnmounted(() => {
      sessionStorage.removeItem("prize");
    });
    (function (designWidth, maxWidth) {
      var doc = document,
        win = window,
        docEl = doc.documentElement,
        remStyle = document.createElement("style"),
        tid;
      function refreshRem() {
        var width = docEl.getBoundingClientRect().width; //屏幕宽度
        maxWidth = maxWidth || 480; //设置最大宽度
        width < 800 && (width = 800); //设置最小宽度
        width > maxWidth && (width = maxWidth);
        var rem = width * 120 / designWidth; //屏幕宽度 / 设计稿宽度 * 100，若为电脑运行，此时rem=100
        remStyle.innerHTML = "html{font-size:" + rem + "px;}"; //此时重新定义html根元素大小为1rem，即100px
      }
      if (docEl.firstElementChild) {
        docEl.firstElementChild.appendChild(remStyle);
      } else {
        var wrap = doc.createElement("div");
        wrap.appendChild(remStyle);
        doc.write(wrap.innerHTML);
        wrap = null;
      }
      //要等 wiewport 设置好后才能执行 refreshRem，不然 refreshRem 会执行2次；
      refreshRem();
      win.addEventListener("resize", function () {
        clearTimeout(tid); //防止执行两次
        tid = setTimeout(refreshRem, 300);
      }, false);
      win.addEventListener("pageshow", function (e) {
        if (e.persisted) {
          // 浏览器后退的时候重新计算
          clearTimeout(tid);
          tid = setTimeout(refreshRem, 300);
        }
      }, false);
      if (doc.readyState === "complete") {
        doc.body.style.fontSize = "54px";
      } else {
        doc.addEventListener("DOMContentLoaded", function (e) {
          doc.body.style.fontSize = "54px";
        }, false);
      }
    })(1920, 1080); //此处传入设计稿宽度及最大宽度

    // 静默登录

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component,
          route
        }) => [!route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: 0
        })) : _createCommentVNode("", true), (_openBlock(), _createBlock(_KeepAlive, null, [route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.fullPath
        })) : _createCommentVNode("", true)], 1024))]),
        _: 1
      }), _withDirectives(_createElementVNode("div", null, [_createVNode(entry)], 512), [[_vShow, _unref(pagePaths).includes(_ctx.$route.name)]]), _unref(pagePath).includes(_ctx.$route.name) ? (_openBlock(), _createBlock(dowApp, {
        key: 0
      })) : _createCommentVNode("", true), _unref(pagePath).includes(_ctx.$route.name) ? (_openBlock(), _createBlock(TabBar, {
        key: 1
      })) : _createCommentVNode("", true)]);
    };
  }
};