import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/home.vue'),
    meta:{
      name:'首页',
      keepAlive:true
    }
  },
  {
    path: "/:catchAll(.*)", // 不识别的path自动匹配404
    redirect: '/',
},
  {
    path: '/notice',
    name: 'notice',
    component: () => import('@/views/home/notice.vue'),
    meta:{
      name:'通知',
      keepAlive:false
    }
  },
  
  {
    path: '/activity',
    name: 'activity',
    meta:{
      name:'活动',
      keepAlive:true
    },
    component: () => import("@/views/promotion/index.vue"),
  },
  {
    path: '/activity/details',
    name: 'activityDetails',
    meta:{
      name:'活动详情',
      keepAlive:false
    },
    component: () => import("@/views/promotion/details.vue"),
  },
  {
    path: '/inviteFriends',
    name: 'inviteFriends',
    meta:{
      name:'邀请',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/agent/index.vue"),
  },
  {
    path: '/member/home',
    name: 'member',
    meta:{
      name:'个人中心',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/index.vue"),
  },
  {
    path: '/gameList',
    name: 'gameList',
    component: () => import('@/views/home/gameList.vue'),
    meta:{
      name:'游戏分类',
      keepAlive:true
    }
  },
  {
    path: '/game',
    name: 'gameType',
    component: () => import('@/views/home/gameType.vue'),
    meta:{
      name:'游戏详情',
      keepAlive:true
    }
  },
  {
    path: '/gameFrame',
    name: 'gameFrame',
    component: () => import('@/views/home/gameFrame.vue'),
    meta:{
      name:'游戏详情',
      keepAlive:false
    }
  },
  {
    path: '/member/rewardCenter',
    name: 'rewardCenter',
    meta:{
      name:'福利',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/rewardCenter.vue"),
  },
  {
    path: '/member/gameRecord',
    name: 'gameRecord',
    meta:{
      name:'投注记录',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/betting.vue"),
  },
  {
    path: '/member/profitandloss',
    name: 'profitandloss',
    meta:{
      name:'收益与损失',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/profitandloss.vue"),
  },
  {
    path: '/member/vouReport',
    name: 'vouReport',
    meta:{
      name:'充值记录',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/vouReport.vue"),
  },
  {
    path: '/member/withdrawReport',
    name: 'withdrawReport',
    meta:{
      name:'充值记录',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/withdrawReport.vue"),
  },
  
  {
    path: '/member/myAccount',
    name: 'myAccount',
    meta:{
      name:'我的账户',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/myAccount.vue"),
  },
  {
    path: '/member/securityCenter',
    name: 'securityCenter',
    meta:{
      name:'安全中心',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/securityCenter/index.vue"),
  },
  {
    path: '/member/securityCenter/loginChange',
    name: 'loginChange',
    meta:{
      name:'修改登录密码',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/securityCenter/loginChange.vue"),
  },
  {
    path: '/member/securityCenter/nickName',
    name: 'nickName',
    meta:{
      name:'修改昵称',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/securityCenter/nickName.vue"),
  },
  {
    path: '/member/securityCenter/birthday',
    name: 'birthday',
    meta:{
      name:'添加生日',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/securityCenter/birthday.vue"),
  },
  
  {
    path: '/member/securityCenter/FundsPasswordBox',
    name: 'FundsPasswordBox',
    meta:{
      name:'交易密码',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/securityCenter/FundsPasswordBox.vue"),
  },
  {
    path: '/member/securityCenter/myBankCards',
    name: 'myBankCards',
    meta:{
      name:'银行卡账号',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/securityCenter/myBankCards.vue"),
  },
  {
    path: '/member/securityCenter/addBankCard',
    name: 'addBankCard',
    meta:{
      name:'添加银行卡账号',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/securityCenter/addBankCard.vue"),
  },
  {
    path: '/member/securityCenter/addBankCardPix',
    name: 'addBankCardPix',
    meta:{
      name:'添加电子钱包',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/securityCenter/addBankCardPix.vue"),
  },
  {
    path: '/member/securityCenter/myEWallet',
    name: 'myEWallet',
    meta:{
      name:'电子钱包',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/securityCenter/myEWallet.vue"),
  },
  
  {
    path: '/withdraw',
    name: 'withdraw',
    meta:{
      name:'提现',
      keepAlive:false,
      needLogin:true,
    },
    component: () => import("@/views/member/withdraw.vue"),
  },
  {
    path: '/member/voucherCenter',
    name: 'voucherCenter',
    meta:{
      name:'充值中心',
      keepAlive:true,
      needLogin:true
    },
    component: () => import("@/views/member/voucherCenter.vue"),
  },
  {
    path: '/member/voucherCenter/info',
    name: 'corporationRecharge',
    meta:{
      name:'存款信息',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/corporationRecharge.vue"),
  },
  
  {
    path: '/member/manualRebate',
    name: 'manualRebate',
    meta:{
      name:'手动返利',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/manualRebate.vue"),
  },
  {
    path: '/member/feedback',
    name: 'feedback',
    meta:{
      name:'意见反馈',
      keepAlive:true,
      needLogin:true
    },
    component: () => import("@/views/member/feedback.vue"),
  },
  {
    path: '/member/webEmail',
    name: 'webEmail',
    meta:{
      name:'通知消息',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/webEmail.vue"),
  },
  {
    path: '/login',
    name: 'login',
    meta:{
      name:'登录',
    },
    component: () => import("@/views/sign/login.vue"),
  },
  {
    path: '/register',
    name: 'register',
    meta:{
      name:'注册',
    },
    component: () => import("@/views/sign/register.vue"),
  },
  {
    path: '/forget',
    name: 'forget',
    meta:{
      name:'忘记密码'
    },
    component: () => import("@/views/sign/forget.vue"),
  },
  {
    path: '/member/myWithdrawCards',
    name: 'myWithdrawCards',
    meta:{
      name:'我的银行卡',
      needLogin:true
    },
    component: () => import("@/views/member/myWithdrawCards.vue"),
  },
  {
    path: '/member/benefits',
    name: 'benefits',
    meta:{
      name:'vip',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/vip/index.vue"),
  },
  {
    path: '/member/receivingCenter',
    name: 'receivingCenter',
    meta:{
      name:'福利',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/receivingCenter.vue"),
  },
  {
    path: '/member/bonus',
    name: 'bonus',
    meta:{
      name:'福利',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/bonus.vue"),
  },
  
  {
    path: '/member/ClaimHistory',
    name: 'ClaimHistory',
    meta:{
      name:'福利记录',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/ClaimHistory.vue"), 
  },
  {
    path: '/member/signIn',
    name: 'signIn',
    meta:{
      name:'签到',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/signIn.vue"),
  },
  {
    path: '/member/rescue',
    name: 'rescue',
    meta:{
      name:'救助金',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/helpMe.vue"),
  },
   
  {
    path: '/member/record',
    name: 'record',
    meta:{
      name:'帐变记录',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/member/record.vue"),
  },
  {
    path: '/member/rebateReport',
    name: 'rebateReport',
    meta:{
      name:'回扣收入',
      keepAlive:false,
      needLogin:false
    },
    component: () => import("@/views/member/rebateReport.vue"),
  },
  {
    path: '/team/overview',
    name: 'overview',
    meta:{
      name:'概述',
      keepAlive:false,
      needLogin:false
    },
    component: () => import("@/views/team/overview.vue"),
  },
  {
    path: '/team/agentRegister',
    name: 'agentRegister',
    meta:{
      name:'账户',
      keepAlive:false,
      needLogin:false
    },
    component: () => import("@/views/team/agentRegister.vue"),
  },
  {
    path: '/team/linkDetail',
    name: 'linkDetail',
    meta:{
      name:'账户',
      keepAlive:false,
      needLogin:true
    },
    component: () => import("@/views/team/linkDetail.vue"),
  },
  {
    path: '/member/team',
    name: 'team',
    meta:{
      name:'团队管理',
      keepAlive:false,
      needLogin:false
    },
    component: () => import("@/views/team/team.vue"),
  },
  {
    path: '/team/teamBetting',
    name: 'teamBetting',
    meta:{
      name:'团队投注',
      keepAlive:false,
      needLogin:false
    },
    component: () => import("@/views/team/teamBetting.vue"),
  },
  {
    path: '/team/pnl',
    name: 'pnl',
    meta:{
      name:'团队盈亏',
      keepAlive:false,
      needLogin:false
    },
    component: () => import("@/views/team/pnl.vue"),
  },
  {
    path: '/team/record',
    name: 'teamRecord',
    meta:{
      name:'团队账户记录',
      keepAlive:false,
      needLogin:false
    },
    component: () => import("@/views/team/record.vue"),
  },
  {
    path: '/member/ios',
    name: 'iOS',
    meta:{
      name:'转盘弹窗',
      keepAlive:false,
      needLogin:false
    },
    component: () => import("@/views/member/ios.vue"),
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(function(to, from, next) {
  window.scrollTo(0,0);
  if (to.meta.needLogin) {
    //页面是否登录
    if (localStorage.getItem("Authorization")) {
      //本地存储中是否有token(uid)数据    
      next(); //表示已经登录
    } else {
      //next可以传递一个路由对象作为参数 表示需要跳转到的页面
      next({
        name: "login"
      });
    }
  } else {
    next(); //继续往后走
  }  
});
export default router
